import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setMobileList } from 'redux/index';
import { setSnackbar } from 'redux/reducers';
import { excludeType } from 'helpers';
import { Add } from '@material-ui/icons';
import { OptionMenu, DashboardToggle, NavBack, RoundButton } from 'components';
import styles from '../userList.module.css';

export default function ListLabel({
	label,
	addToggle,
	addHover,
	menuItems,
	isMd,
}) {
	const seatsPurchased = useSelector(
		(state) => state.user?.agency?.purcahsedSeatQuantity
	);

	const billing = useSelector((state) => state.user?.billing);

	const [buttonStatus, setButtonStatus] = useState(false);

	const dispatch = useDispatch();
	const { push } = useHistory();
	const showNavOption = (excludeType(['child']) || label === 'Support') && isMd;
	const { user } = useSelector(({ user }) => user);

	const checkIfAgencyScreen = () => {
		if (
			label === 'Social Workers' ||
			label === 'Managers' ||
			label === 'Admins'
		) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		if (
			!seatsPurchased ||
			!checkIfAgencyScreen() ||
			user.type !== 'agencyuser'
		) {
			setButtonStatus(false);
		} else {
			const activeUsers = billing?.activeUsers + billing?.invitedUsers;
			setButtonStatus(activeUsers < seatsPurchased ? false : true);
		}
	}, []);

	const plusButtonStyle = {
		width: '58px',
		height: '58px',
		iconSize: '40px',
	};

	const plusButtonPosition = {
		position: 'absolute',
		right: '10px',
	};

	const handleOnClick = () => {
		if (checkIfAgencyScreen()) {
			const message =
				user.role === 'admin'
					? 'Navigate to support and contact us to purchase additional seats.'
					: 'Please contact your admin to add additional users.';
			if (buttonStatus) {
				dispatch(setSnackbar({ variant: 'error', message: message }));
			} else {
				dispatch(setMobileList(false));
				addToggle();
			}
		} else {
			dispatch(setMobileList(false));
			addToggle();
		}
	};

	return (
		<div className={styles.root}>
			{showNavOption &&
				(user?.id ? (
					<DashboardToggle className={styles.listLabelDashToggle} />
				) : (
					<NavBack
						onClick={() => {
							dispatch(setMobileList(false));
							push('/login/');
						}}
						className={styles.listLabelDashToggle}
					/>
				))}
			{label}
			{addHover ? (
				addToggle ? (
					<RoundButton
						buttonStatus={buttonStatus}
						Icon={Add}
						buttonClass='list-add-button'
						style={{ ...plusButtonStyle, ...plusButtonPosition }}
						onClick={() => handleOnClick()}
						hoverText={addHover}
					/>
				) : menuItems ? (
					<OptionMenu
						style={plusButtonPosition}
						menuItems={menuItems}
						divButton>
						<RoundButton
							Icon={Add}
							buttonStatus={buttonStatus}
							style={plusButtonStyle}
							hoverText={addHover}
						/>
					</OptionMenu>
				) : null
			) : null}
		</div>
	);
}
