import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAgencyManagerUsers } from 'redux/selectors';
import { useSelectorListState } from 'helpers/hooks';
import { useGetBranchButtons } from '../utils/useGetBranchButtons';
import useManageBranchState from '../utils/useManageBranchState';
import { BranchBasicInfo, BranchManagerList } from './';
import { PageLayout, AddressForm, SectionHeader } from 'components';

export default function ManageBranch() {
	const { branchId } = useParams();
	const { name } = useManageBranchState();

	const managerList = useSelector(getAgencyManagerUsers);

	let assignedManagerList = [];

	if (branchId && managerList) {
		assignedManagerList = managerList.filter(
			(val) => val.branch?.id === branchId
		);
	}

	const managerListState = useSelectorListState(
		managerList,
		assignedManagerList,
		{ loaded: true }
	);

	const getBody = () => {
		const reqBody = { name: name.value, id: branchId, required: false };
		return {
			reqBody,
			managerChanges: managerListState.changes,
		};
	};

	const buttonArray = useGetBranchButtons(getBody);

	return (
		<PageLayout
			title={branchId ? 'Edit Branch' : 'Create Branch'}
			buttonArray={buttonArray}
			align='left'>
			<BranchBasicInfo inputs={[name]} />
			<SectionHeader>ADDRESS</SectionHeader>
			<AddressForm key={branchId} required={false} />
			<BranchManagerList selectorListState={managerListState} />
		</PageLayout>
	);
}
