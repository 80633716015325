import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as service from 'services';
import { errors } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { handleBranchValidation } from 'helpers/validation/branchValidation';
//import { useAddressGeocode } from 'components/UtilityComponents/AddressForm/AddressForm';
import { PrimaryButton, SecondaryButton } from 'components';
import { getAddress } from 'redux/selectors';

export const useGetBranchButtons = (getBody) => {
	const history = useHistory();
	const { setLoader, setSnackbarError, setSnackbar } = useCreateDispatches();
	const params = useParams();
	const { branchId } = params;
	//const getGeocode = useAddressGeocode();
	const addressState = useSelector(getAddress);

	const branches = useSelector((state) => state.user.branches);

	function CancelButton() {
		const onClick = () => history.goBack();
		return <SecondaryButton onClick={onClick}>Cancel</SecondaryButton>;
	}

	function SaveButton() {
		const isEdit = !!branchId;

		const getText = () => {
			return !isEdit ? 'Save Branch' : 'Save Changes';
		};

		const errorHandler =
			(error) =>
			({ response }) => {
				setSnackbarError({ response, ...error }, { log: true });
			};

		const addToBranch = async (user) => {
			return await service.updateUser({ branchId }, user.id);
		};

		const removeFromBranch = async (user) => {
			return await service.updateUser({ branchId: '' }, user.id);
		};

		const branchRequest = async (reqBody, addressState) => {
			if (!isEdit) {
				const duplicateBranch = branches.some((branch) => {
					const branchLowerCase = branch.name.toLowerCase();
					const branchTrim = branchLowerCase.trim();
					const newNameLowerCase = reqBody.name.toLowerCase();
					const newNameTrim = newNameLowerCase.trim();
					return branchTrim === newNameTrim;
				});
				if (duplicateBranch) {
					return 'duplicate';
				}
			}
			const reqFunction = isEdit ? service.updateBranch : service.createBranch;

			return await reqFunction({
				...reqBody,
				address: { ...addressState },
			});
		};

		const onClick = async () => {
			setLoader(true);
			const { reqBody, managerChanges } = getBody();
			const validation = handleBranchValidation(reqBody.name);

			if (validation.isValid) {
				const promiseArray = [await branchRequest(reqBody, addressState)];
				if (isEdit) {
					managerChanges.add?.forEach((val) =>
						promiseArray.push(addToBranch(val))
					);
					managerChanges.remove?.forEach((val) =>
						promiseArray.push(removeFromBranch(val))
					);
				}

				Promise.all(promiseArray)
					.then(async (values) => {
						if (values[0]?.error) {
							setSnackbar(
								{
									variant: 'error',
									message: values[0]?.response?.data?.message,
								},
								{ log: true }
							);
							setLoader(false);
							return;
						}
						const BranchData = values[0]?.data || {};

						const { id } = BranchData;

						if (!isEdit) {
							managerChanges?.add?.forEach(
								async (val) =>
									await service.updateUser({ branchId: id }, val.id)
							);
						}

						await Promise.all([
							service.getBranchList(),
							service.setChildrenList(),
							service.getAgencyUsers(),
						]);

						if (!isEdit && id) {
							history.push(`/agencies/manage/${id}`);
						} else {
							return BranchData;
						}
					})
					.catch(errorHandler(errors.BRANCH_SAVE));
			} else {
				setSnackbar(
					{ variant: 'error', message: validation.message },
					{ log: true }
				);
			}
			setSnackbar({ variant: 'success', message: 'Success' }, { log: true });
			setLoader(false);
		};

		return <PrimaryButton onClick={onClick}>{getText()}</PrimaryButton>;
	}

	return [<CancelButton key='cancel' />, <SaveButton key='save' />];
};
