import axios from 'axios';
import { setLoader, store } from 'redux/index';
import queryString from 'query-string';
import { logoutUser } from 'services';

const v2 = axios.create({
	baseURL: process.env.REACT_APP_V2_BASE_URL,
});

v2.interceptors.request.use(
	(config) => {
		if (
			['/session/authenticate', '/session/authenticate/auto'].includes(
				config.url
			)
		) {
			return config;
		}

		if (config.url[0] === '/') {
			try {
				const query = window.location.search;
				const parsedQuery = query ? queryString.parse(query) : {};
				const queryToken = parsedQuery.token;
				const storageToken = localStorage.getItem('token');
				const cokToken = localStorage.getItem('cok_token');
				const originalToken = localStorage.getItem('originalUser');
				let token = queryToken || storageToken;
				const timeout = localStorage.getItem('timeout');
				const expirationLimit = timeout ? Number(timeout) : 900000;
				const setActiveTime = new Date().getTime() + expirationLimit;
				localStorage.setItem('active', setActiveTime);

				if (!token && config.method === 'get') {
					logoutUser();
				}

				if (!token && !originalToken) {
					logoutUser();
				}

				if (originalToken && config.readOnlyToken) {
					config.headers['Authorization'] = `Bearer ${originalToken}`;
				}

				if (token && !config.readOnlyToken) {
					config.headers['Authorization'] = `Bearer ${token}`;
				}

				if (config?.data?.pass || config.pass) {
					const authPass = config?.data?.pass ? config.data.pass : config.pass;
					config.headers['Authorization-Verify'] = authPass;
				}

				if (config.url === '/cok/case') {
					config.headers['X-COK-Authorization'] = `Bearer ${cokToken}`;
				}
			} catch (e) {
				console.log(e);
			}
		}
		if (config.url.includes('s3.') && !config.headers['Content-Type']) {
			config.headers['Content-Type'] = 'image/jpeg';
		}

		return config;
	},

	(error) => {
		return Promise.reject(error);
	}
);

v2.interceptors.response.use(
	(res) => res,
	(err) => {
		const { status, data: { context } = {} } = err?.response || {};
		if (status === 401 && context === 'mfa-required') {
			store.dispatch(setLoader(false));
			if (!window?.location.pathname.includes('/confirm2fa')) {
				window.location.href = '/confirm2fa/';
			}
		}
		throw err;
	}
);

export default v2;
