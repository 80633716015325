import { Switch, Route, Redirect } from 'react-router-dom';
import { AutoLogout } from '../helpers';

//logged in routes
import AccessRoute from './AccessRoute';
import PrivateRoute from './PrivateRoute';
import Require2FA from 'components/MultiFactor/Require2fa';

// component imports
import {
	Contributors,
	Lifebooks,
	Homes,
	Agency,
	Administrators,
	Managers,
	ResourceParents,
	SocialWorkers,
	OwnerProfile,
	Support,
	ErrorPage,
	Vault,
	Snapshots,
	CasaAgency,
	ManageCasa,
	CasaUsers,
	CasaConnections,
	Calendar,
	Billing,
	CalendarActivity,
	AgencyActivitiesFeed,
} from '../components';
import Enforce2fa from 'components/MultiFactor/Enforce2fa';

export const LoggedInRouter = () => {
	return (
		<>
			<AutoLogout />
			<Require2FA />
			<Switch>
				<AccessRoute
					key='enforce2fa'
					path='/enforce2fa/'
					component={Enforce2fa}
					authorized={{
						agencyuser: true,
						contributor: true,
						csw: true,
						casauser: true,
					}}
				/>

				<AccessRoute
					key='casaAgency'
					path='/casaAgency/'
					component={CasaAgency}
					authorized={{
						agencyuser: { role: ['admin', 'manager'] },
					}}
				/>
				<AccessRoute
					key='casa'
					path='/casa/'
					component={ManageCasa}
					authorized={{ casauser: { role: ['admin'] } }}
				/>
				<AccessRoute
					key='casa'
					path='/casaUsers/'
					component={CasaUsers}
					authorized={{ casauser: { role: ['admin'] } }}
				/>
				<AccessRoute
					key='casa'
					path='/casaUsers/manage/:casaUserId?'
					component={CasaUsers}
					authorized={{ casauser: { role: ['admin'] } }}
				/>
				<AccessRoute
					key='casa'
					path='/casaConnections/'
					component={CasaConnections}
					authorized={{ casauser: { role: ['admin'] } }}
				/>
				<AccessRoute
					key='account'
					path='/account/'
					component={OwnerProfile}
					authorized={{
						agencyuser: true,
						contributor: true,
						csw: true,
						casauser: true,
						child: { status: ['active'] },
					}}
				/>
				<AccessRoute
					key='administrator'
					path='/admins/manage/:adminId?'
					component={Administrators}
					authorized={{ agencyuser: { role: ['admin'] } }}
					nonMobile
				/>
				<AccessRoute
					key='administrator'
					path='/admins/:adminId?'
					component={Administrators}
					authorized={{ agencyuser: { role: ['admin'] } }}
					nonMobile
				/>
				<AccessRoute
					key='agency'
					path='/agencies/manage/:branchId?'
					access='/agency'
					component={Agency}
					authorized={{ agencyuser: { role: ['admin'] } }}
					nonMobile
				/>
				<AccessRoute
					key='agencyactivities'
					path='/agencyactivities'
					component={AgencyActivitiesFeed}
					authorized={{ agencyuser: { role: ['admin'] } }}
					nonMobile
				/>
				<AccessRoute
					key='agency'
					path='/agencies/:branchId?'
					access='/agency'
					component={Agency}
					authorized={{ agencyuser: { role: ['admin'] } }}
					nonMobile
				/>
				<AccessRoute
					key={`vault`}
					path='/vault/:feedType?/:childId?'
					component={Vault}
					authorized={{
						csw: true,
						agencyuser: true,
						child: true,
					}}
					nonMobile
				/>
				<AccessRoute
					key='drafts'
					path='/archive/:feedType?/:childId?'
					component={Snapshots}
					authorized={{ agencyuser: { role: ['admin', 'manager'] } }}
					nonMobile
				/>
				<AccessRoute
					key='viewContributors'
					path='/contributors/:pageType?/:contributorId?'
					component={Contributors}
					authorized={{ agencyuser: true, casauser: true }}
				/>
				<AccessRoute
					key='viewHome'
					path='/homes/manage/:houseId?'
					component={Homes}
					authorized={{ agencyuser: true }}
					fallBackRoute='/homes/'
					nonMobile
					privileged={true}
				/>
				<AccessRoute
					key='viewHome'
					path='/homes/'
					component={Homes}
					authorized={{ agencyuser: true }}
					nonMobile
				/>
				<PrivateRoute
					exact
					path='/lifebooks/:feedType?/:childId/:postId?'
					component={Lifebooks}
				/>
				<PrivateRoute path='/lifebooks/:feedType?/' component={Lifebooks} />
				<AccessRoute
					key='manager'
					path='/managers/manage/:managerId?'
					component={Managers}
					authorized={{ agencyuser: { role: ['admin', 'manager'] } }}
					nonMobile
				/>
				<AccessRoute
					key='manager'
					path='/managers/:managerId?'
					component={Managers}
					authorized={{ agencyuser: { role: ['admin', 'manager'] } }}
					nonMobile
				/>
				<AccessRoute
					key='resourceParents'
					path='/resource-parents/:pageType?/:rpId?'
					component={ResourceParents}
					authorized={{ agencyuser: true }}
					fallbackRoute='/resource-parents'
				/>
				<AccessRoute
					key='socialWorkerReport'
					path='/socialworkers/report'
					access='/agency'
					component={SocialWorkers}
					authorized={{ agencyuser: { role: ['admin', 'manager'] } }}
					nonMobile
				/>
				<AccessRoute
					key='socialWorker'
					path='/socialworkers/manage/:swId?'
					component={SocialWorkers}
					authorized={{ agencyuser: { role: ['admin', 'manager'] } }}
					nonMobile
				/>
				<AccessRoute
					key='socialWorker'
					path='/socialworkers/:swId?'
					component={SocialWorkers}
					authorized={{ agencyuser: { role: ['admin', 'manager'] } }}
					nonMobile
				/>
				<AccessRoute
					key='calendar'
					path='/calendar/:childId?'
					component={Calendar}
					authorized={{
						csw: true,
						agencyuser: true,
						child: true,
						contributor: true,
						casauser: true,
					}}
				/>
				<AccessRoute
					key='calendarActivity'
					path='/calendarActivity/:childId?'
					component={CalendarActivity}
					authorized={{
						csw: true,
						agencyuser: true,
						child: true,
						contributor: { role: ['resource-parent'] },
						casauser: true,
					}}
				/>
				<AccessRoute
					key='billing'
					path='/billing'
					component={Billing}
					authorized={{ agencyuser: { role: ['admin'] } }}
				/>
				<AccessRoute
					path='/support/:supportId?'
					component={Support}
					authorized={{
						child: true,
						csw: true,
						agencyuser: true,
						contributor: true,
						casauser: true,
					}}
				/>
				<Route path='/error/' component={ErrorPage} />
				<Route
					path='/'
					render={() => {
						let redirectPath = localStorage.getItem('token')
							? '/lifebooks/'
							: '/login/';
						return <Redirect to={redirectPath} />;
					}}
				/>
			</Switch>
		</>
	);
};

export default LoggedInRouter;
