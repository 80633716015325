import styles from '../pageLayout.module.css';
import { useState, useEffect } from 'react';

export default function DefaultHeader({ title }) {
	const [pageTitle, setPageTitle] = useState(title);

	useEffect(() => {
		if (title.includes('undefined')) {
			setPageTitle(null);
		}
	}, [title]);

	return (
		<div className={styles.defaultHeaderRoot}>
			<div className={styles.defaultHeaderContent}>{pageTitle}</div>
		</div>
	);
}
