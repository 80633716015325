import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSnackbar, setLoader, setUser } from 'redux/reducers';
import useAcceptInviteState from './utils/useAcceptInviteState';
import useAcceptInviteHandlers from './utils/useAcceptInviteHandlers';
import PrivacyPolicy from './components/PrivacyPolicy';
import InviteSubmitButton from './components/InviteSubmitButton';
import * as Comp from 'components';
import styles from './invite.module.css';

function Invite() {
	const infoState = useAcceptInviteState();
	const { user, invalidToken, policyAccepted, setPolicyAccepted } = infoState;
	const { handleCancel, handleValidate } = useAcceptInviteHandlers();

	const profileType = {
		admin: 'Administrator',
		manager: 'Manager',
		'social-worker': 'Social Worker',
		mentor: 'Mentor',
		casauser: 'Casa',
	};

	const buttonContainer = [
		<Comp.SecondaryButton key={'secondary-button'} onClick={handleCancel}>
			Cancel
		</Comp.SecondaryButton>,
		<InviteSubmitButton
			user={user}
			key={'invite-button'}
			infoState={infoState}
			handleValidate={() => handleValidate(infoState)}
		/>,
	];

	const identity = profileType[user.role || user.type || '']
		? `New ${profileType[user.role || user.type || '']} Account`
		: 'New Account';

	return invalidToken ? (
		<Comp.InvalidToken error={invalidToken} />
	) : (
		<div className={styles.agencyInviteContent}>
			<Comp.PageLayout title={identity} buttonArray={buttonContainer}>
				<div className={styles.inviteContainer}>
					<div className={styles.inviteContent}>
						<Comp.FormSectionHeader
							className={styles.agencyInviteSectionHeader}>
							<div>Profile Information</div>
						</Comp.FormSectionHeader>
						<Comp.ProfileBasics
							infoState={infoState}
							user={user}
							staticEmail={true}
						/>
						<PrivacyPolicy
							policyAccepted={policyAccepted}
							setPolicyAccepted={setPolicyAccepted}
						/>
					</div>
				</div>
			</Comp.PageLayout>
		</div>
	);
}

const mapStateToProps = ({ user: { user } }) => {
	return { user };
};

export default withRouter(
	connect(mapStateToProps, { setSnackbar, setLoader, setUser })(Invite)
);
