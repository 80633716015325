import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLifebooksChild } from 'redux/selectors';
import { getSchoolInfo, getGoogleSchoolInfo } from 'services';
import { useSetState, useFormInput } from 'helpers';

const defaultState = {
	id: null,
	name: '',
	street: '',
	city: '',
	state: '',
	postal: '',
};

export default function useManageChildSchoolState() {
	const child = useSelector(getLifebooksChild);
	const [cancelled, setCancelled] = useState(false);
	const { schoolId, id: childId } = child;
	const [state, setState] = useSetState({
		...defaultState,
	});

	useEffect(() => {
		if (schoolId !== state.id) {
			setCancelled(false);
			populateSchool();
		} else {
			setCancelled(true);
			clearSchoolData();
		}

		return () => {
			setCancelled(true);
			clearSchoolData();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId]);

	const schoolName = useFormInput('Name', '');

	const handleSchoolName = (name) => {
		schoolName.onChange({ value: name });
	};

	const clearSchoolData = () => {
		setState({ ...defaultState });
		handleSchoolName('');
	};

	const populateFields = (school = {}) => {
		const { id, address, name } = school;
		if (id) {
			const { street, city, state, postal } = address || {};
			setState({
				id: id || null,
				street: street || '',
				city: city || '',
				state: state || '',
				postal: postal || '',
			});

			handleSchoolName(name || '');
		} else {
			clearSchoolData();
		}
	};

	const handleSchoolSelect = async (id) => {
		await getGoogleSchoolInfo(id).then(({ data }) => {
			populateFields(data);
		});
	};

	const getCancelled = () => cancelled;

	const populateSchool = async () => {
		if (schoolId) {
			await getSchoolInfo(schoolId).then(({ data }) => {
				if (!getCancelled()) {
					populateFields(data[0]);
				}
			});
		} else {
			clearSchoolData();
		}
	};

	const infoArray = [
		{ label: 'Address', value: state.street },
		{ label: 'City', value: state.city },
		{ label: 'State', value: state.state },
		{ label: 'Zip', value: state.postal },
	];

	return {
		schoolState: state,
		schoolName,
		clearSchoolData,
		handleSchoolSelect,
		infoArray,
	};
}
