import { apiV2 } from '../axios';
import {
	store,
	setSnackbar,
	setAgency as setAgencyState,
	setAgencyHash,
	setAgencyLoaded,
	setAgencyUsers,
	setBranchList,
	setBranches,
	setBranchHash,
} from '../redux';
import {
	hashList,
	excludeRole,
	mixpanelTrackInviteSent,
	listSortPersonByName,
} from '../helpers';
import {
	getUserFromToken,
	getTokenHeaders,
	createUser,
	updateUser,
	deleteUser,
} from './';
import { getUser, reinviteUser } from './user-services';

export const getAgencyUsers = async (type) => {
	store.dispatch(setAgencyLoaded(false));
	const { agencyId } = await getUserFromToken();
	let response = await apiV2
		.get(`/user?agencyId=${agencyId}`)
		.then((res) => {
			if (res.data) {
				store.dispatch(setAgencyHash(hashList(res.data)));
				const sortedData = listSortPersonByName(res.data, 'lastname', 'email');
				store.dispatch(setAgencyUsers(sortedData));
				return sortedData;
			}
			return [];
		})
		.catch((err) => {
			store.dispatch(
				setSnackbar({
					variant: 'error',
					message: 'Could not retrieve agency list.',
				})
			);
			return { error: true, response: err.response };
		})
		.finally(() => {
			store.dispatch(setAgencyLoaded(true));
		});
	if (!response.error && type) {
		let filteredList = type
			? response.filter((val) => {
					return val.role === type;
			  })
			: response;

		// TODO: retrieve profile photos for agency users
		response = filteredList;
	}
	return response;
};

export const getAgencyUser = async (userId) => {
	return await getUser(userId);
};

export const getAgency = async (token) => {
	const user = await getUserFromToken(token);
	let agencyResponse = await apiV2
		.get(`/agency/${user.agencyId}`)
		.catch((err) => {
			store.dispatch(
				setSnackbar({
					variant: 'error',
					message: 'Could not retrieve agency information',
				})
			);
			return { error: true, response: err.response };
		});
	return agencyResponse;
};

export const updateAgency = async (reqBody) => {
	const response = await apiV2.put(`/agency/${reqBody.id}`, reqBody);
	await setAgency();
	return response;
};

export const setAgency = async (token) => {
	let agency = await getAgency(token);
	if (agency?.data?.enforceSessionTTLInSeconds) {
		const timeout = agency.data.enforceSessionTTLInSeconds * 1000;
		localStorage.setItem('timeout', timeout);
	}
	if (agency && agency.data) {
		const { user } = store.getState().user || {};
		if (
			user &&
			excludeRole(['social-worker', 'mentor', 'privileged']) &&
			user.status &&
			user.status !== 'invited'
		) {
			await getAgencyUsers();
		}
		store.dispatch(setAgencyState(agency.data));
	}
	return agency;
};

export const inviteAgencyUser = async (agencyUser = {}) => {
	agencyUser.type = 'agencyuser';
	let result = await createUser(agencyUser);
	result?.data && mixpanelTrackInviteSent('sent', result.data);
	return result?.data;
};

export const updateAgencyUserRole = async (id, role) => {
	return await updateUser({ role }, id);
};

export const archiveAgencyUser = async (id, pass) => {
	// uses auth-verify header //
	return await deleteUser(id, pass);
};

export const createBranch = async (reqBody) => {
	let result = apiV2.post('/branch', reqBody).catch((err) => {
		return { error: true, response: err.response };
	});
	return result;
};

export const getBranchList = async () => {
	let result = apiV2
		.get(`/branch`)
		.then((res) => {
			store.dispatch(setBranchList(res.data.map((val) => val.id)));
			store.dispatch(setBranches(res.data));
			store.dispatch(setBranchHash(hashList(res.data)));
			return res;
		})
		.catch((err) => {
			return { error: true, response: err.response };
		});
	return result;
};

export const getBranch = async (branchId, token) => {
	const config = token ? getTokenHeaders(token) : {};
	let result = apiV2.get(`/branch/${branchId}`, config).catch((err) => {
		return { error: true, response: err.response };
	});
	return result;
};

export const updateBranch = async (branch) => {
	let result = await apiV2.put(`/branch/${branch.id}`, branch).catch((err) => {
		return { error: true, response: err.response };
	});
	return result;
};

export const updateAgencyUser = async (agencyUser) => {
	return await updateUser(agencyUser, agencyUser.id);
};

export const reinviteAgencyUser = async (id) => {
	return await reinviteUser(id);
};

export const enableSWPrivilege = async (id) => {
	return await updateAgencyUser({ id, privileged: true });
};

export const disableSWPrivilege = async (id) => {
	return await updateAgencyUser({ id, privileged: false });
};
