import { useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import List from '@material-ui/icons/List';
import ExitToApp from '@material-ui/icons/ExitToApp';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import Schedule from '@material-ui/icons/Schedule';
import BookOpenPageVariant from 'mdi-material-ui/BookOpenPageVariant';
import CalendarMonth from 'mdi-material-ui/CalendarMonth';
import FileEditOutline from 'mdi-material-ui/FileEditOutline';
import OndemandVideo from '@material-ui/icons/OndemandVideo';
import { ContactSupport } from '@material-ui/icons';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import queryString from 'query-string';
import { IndicatorCount } from 'components';
import { getLifebooksChild } from 'redux/selectors';
import { usePasswordConfirm, errors } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { logoutUser, initializeUser, getUserFromToken } from 'services';
import styles from '../lifebookNav.module.css';
import { getUserWithVerify } from 'services/user-services';
import { setSnackbarReadOnly } from 'redux/reducers/snackbarReducer';

export default function useNavOptions(root) {
	const { childId } = useParams();
	const { push } = useHistory();
	const { search } = useLocation();
	const { setLoader, setSnackbarError } = useCreateDispatches();
	const [pass, setPass] = useState();
	const dispatch = useDispatch();

	const { token } = queryString.parse(search);
	const tokenQueryString = token ? `?token=${token}` : '';

	const isVault = window.location.href.includes('vault');
	const timelineLabel = isVault ? 'Posts' : 'LifeBook';

	const { pendingPostCount } = useSelector(getLifebooksChild);

	const getExitReadOnlyAttempts = () => {
		const eroa = localStorage.getItem('eroa');
		return Number(atob(eroa));
	};

	const setExitReadOnlyAttempts = (val) =>
		localStorage.setItem('eroa', btoa([val]));

	const onReadOnlyPasswordConfirm = usePasswordConfirm({
		onFailure: () => {
			setSnackbarError(errors.READ_ONLY_PASSWORD);
			logoutUser();
		},
		maxAttempts: 3,
		getAttempts: getExitReadOnlyAttempts,
		setAttempts: setExitReadOnlyAttempts,
	});

	const navOptions = {
		profile: {
			label: 'Profile',
			onClick: () => {
				const path =
					root === 'lifebooks' ? 'lifebooks/manage' : `${root}/child`;
				push(`/${path}/${childId}${tokenQueryString}`);
			},
			icon: <AccountCircle />,
		},
		timeline: {
			label: timelineLabel,
			onClick: () => {
				push(`/${root}/timeline/${childId}${tokenQueryString}`);
			},
			icon: <BookOpenPageVariant />,
		},
		activity: {
			label: 'Activity',
			onClick: () => {
				push(`/${root}/activity/${childId}${tokenQueryString}`);
			},
			icon: <List />,
		},
		drafts: {
			label: 'Drafts',
			onClick: () => {
				push(`/${root}/drafts/${childId}${tokenQueryString}`);
			},
			icon: <FileEditOutline />,
		},
		services: {
			label: 'Services',
			onClick: () => {
				push(`/${root}/services/${childId}${tokenQueryString}`);
			},
			icon: <AccessAlarm />,
		},
		pending: {
			label: 'Pending',
			onClick: () => {
				push(`/${root}/pending/${childId}${tokenQueryString}`);
			},
			icon: <Schedule />,
			indicator: pendingPostCount && (
				<IndicatorCount className={styles.lifebookNavIndicator}>
					{`${pendingPostCount}` || '0'}
				</IndicatorCount>
			),
		},
		exportRequest: {
			label: 'Export Requests',
			onClick: () => {
				push(`/${root}/exports/${childId}`);
			},
			icon: <Schedule />,
		},
		vaultFiles: {
			label: 'Files',
			onClick: () => {
				push(`/vault/files/${childId}`);
			},
			icon: <InsertDriveFile />,
		},
		vaultActivity: {
			label: 'Activity',
			onClick: () => {
				push(`/vault/vaultActivity/${childId}`);
			},
			icon: <List />,
		},
		calendarActivity: {
			label: 'Activity',
			onClick: () => {
				push(`/calendarActivity/${childId}`);
			},
			icon: <List />,
		},
		calendar: {
			label: 'Calendar',
			onClick: () => {
				push(`/calendar/${childId}`);
			},
			icon: <CalendarMonth />,
		},
		contact: {
			label: 'Contact Us',
			icon: <ContactSupport />,
			onClick: () => {
				push('/support/contact');
			},
		},
		videos: {
			label: 'Tutorials',
			icon: <OndemandVideo />,
			onClick: () => {
				push('/support/videos');
			},
		},
		manual: {
			label: 'Manual',
			icon: <PictureAsPdf />,
			onClick: () => {
				push('/support/manual');
			},
		},
		exit: {
			label: 'Exit',
			onClick: () => {
				localStorage.removeItem('token');
				push('/login/');
			},
			icon: <ExitToApp />,
		},
		exitReadOnly: {
			label: 'Exit',
			onClick: () => {
				const originalToken = localStorage.getItem('originalUser');
				onReadOnlyPasswordConfirm((pass) => {
					setPass(pass);
					setLoader(true);
					getUserFromToken(originalToken).then((e) => {
						getUserWithVerify(e.userId, pass)
							.then(async () => {
								localStorage.removeItem('eroa');
								localStorage.setItem('token', originalToken);
								await initializeUser('login', () => setLoader(false)).then(
									(res) => {
										localStorage.removeItem('originalUser');
										push('/lifebooks/');
									}
								);
							})
							.catch((err) => {
								setLoader(false);
								dispatch(
									setSnackbarReadOnly({
										message: 'Wrong password please try again.',
									})
								);
							});
					});
				});
			},
			icon: <ExitToApp />,
		},
	};

	return navOptions;
}
