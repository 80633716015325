import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import AuthCode from 'react-auth-code-input';
import { useCreateDispatches, useQuery } from 'helpers/hooks';
import { PrimaryButton, SecondaryButton } from 'components';
import styles from '../../Account/account.module.css';
import { modalStyle } from '../../MultiFactor/modalStyles';
import { useHistory } from 'react-router-dom';
import * as services from 'services';

const TwoFactorModal = (props) => {
	const authCodeRef = useRef(null);
	const modalOpen = useSelector((state) => state.user.twoFactorModal);

	const [passcode, setPasscode] = useState('');

	const { pathname, token } = useQuery();
	const history = useHistory();
	const { setTwoFactorModal, setSnackbar, setLoader } = useCreateDispatches();

	const handleValidate = () => {
		if (passcode.length !== 6) {
			return false;
		} else {
			return true;
		}
	};

	const handleCancel = () => {
		setTwoFactorModal(false);
	};

	const handleSubmit = () => {
		login();
	};

	const loadingFalse = () => {
		const path = pathname || 'lifebooks';
		const tokenQuery = token ? `?token=${token}` : '';
		history.push(`/${path}/${tokenQuery}`);
		setLoader(false);
	};

	const handleError = async (error) => {
		if (error?.message?.response?.data?.status === 403) {
			setSnackbar({
				variant: 'error',
				message: error?.message?.response?.message,
				twoFactor: true,
			});
		} else {
			setSnackbar({
				variant: 'error',
				message: 'There was an error processing you request',
				twoFactor: true,
			});
		}
		authCodeRef.current.clear();
		setLoader(false);
	};

	const login = async () => {
		const expirationLimit = 1000 * 60 * 60;
		const setActiveTime = new Date().getTime() + expirationLimit;
		const valid = handleValidate();

		// from will redirect back to protected route if login screen
		// was routed to from protected route
		// validate that fields are filled out
		if (valid) {
			setLoader(true);

			const reqBody = {
				email: props.username?.value,
				password: props.password?.value,
				'2FACode': passcode,
			};

			services
				.loginEmailUser(reqBody)
				.then(async (loginResponse) => {
					if (!loginResponse.error) {
						localStorage.setItem('active', setActiveTime);
						await services.setHeader(loginResponse);
						await services.initializeUser('login', loadingFalse);
					} else {
						handleError(loginResponse);
					}
				})
				.catch((error) => {
					handleError(error);
				});
		} else {
			setSnackbar(
				{ variant: 'error', message: 'Passcode must be 6 digits' },
				{ loggedIn: false }
			);
			setLoader(false);
		}
	};

	return (
		<div style={{ zIndex: 5000 }}>
			<Modal
				ariaHideApp={false}
				style={modalStyle}
				isOpen={modalOpen}
				transparent={false}>
				<div className={styles.modalContainer}>
					<div className={styles.textContainer}>Two-Factor Authentication </div>
					<AuthCode
						ref={authCodeRef}
						containerClassName={styles.authContainer}
						inputClassName={styles.authInput}
						onChange={(e) => setPasscode(e)}
					/>
					<div className={styles.lowerTextContainer}>
						<p>
							A message with a verification code has been sent to your devices.
							Enter the code to continue.
						</p>
					</div>
					<div className={styles.buttonContainer}>
						<PrimaryButton onClick={() => handleSubmit()}>Submit</PrimaryButton>
						<SecondaryButton onClick={() => handleCancel()}>
							Close
						</SecondaryButton>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default TwoFactorModal;
