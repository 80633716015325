import { decodeToken } from './';

export function getTokenType(token) {
	const { partner, type, exp } = (token && decodeToken(token)) || {};
	switch (true) {
		case partner === 'ConnectOurKids':
			return 'connect';
		case ['auditoraccess'].includes(type):
			return type;
		case !!exp:
			return 'readonly';
		default:
			return 'default';
	}
}

export function getTokenUser(token) {
	if (!token) return {};
	const decodedToken = decodeToken(token);
	return { ...decodedToken, tos: false };
}
