import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const Require2FA = () => {
	const user = useSelector((state) => state.user.user);
	const history = useHistory();
	const location = useLocation();

	// enforce2FA under contributor, 2faEnabled

	useEffect(() => {
		if (location?.pathname !== '/enforce2fa/') {
			if (user?.agency?.enforce2FA && !user['2FAEnabled']) {
				history.push('/enforce2fa/');
			}
			if (user?.enforce2FA && !user['2FAEnabled']) {
				history.push('/enforce2fa/');
			}
		}
	}, [location]);
};

export default Require2FA;
