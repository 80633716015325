import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as redux from 'redux/selectors';
import { useSelectorListState } from 'helpers/hooks';
import { getButtonArray } from '../utils/buttons';
import useManageHouseState from '../utils/useManageHouseState';
import {
	PageLayout,
	AddressForm,
	SectionHeader,
	StaticAddressForm,
} from 'components';
import * as HouseComp from './';

export default function ManageHouse() {
	const user = useSelector((state) => state.user.user);
	const { houseId } = useParams();
	const { name, assignedParentList, assignedParentListLoaded } =
		useManageHouseState();
	const reduxHouseChildren = useSelector(
		redux.getLifebooksHouseChildren(houseId)
	);
	const house = useSelector((state) => state.homes.home);
	const pageTitle = houseId ? `${house.name} Household` : 'Add House';

	const childrenList = useSelector(redux.getLifebooksChildren);
	const childrenListState = useSelectorListState(
		childrenList,
		reduxHouseChildren
	);

	const resourceParentList = useSelector(redux.getHomesRPList);

	const resourceParentListState = useSelectorListState(
		resourceParentList,
		assignedParentList,
		{
			loaded: assignedParentListLoaded,
		}
	);

	const resetListChanges = () => {
		childrenListState.resetChanges();
		resourceParentListState.resetChanges();
	};

	const getBody = () => {
		const reqBody = { name: name.value };
		return {
			reqBody,
			houseId,
			childChanges: childrenListState.changes,
			resourceParentChanges: resourceParentListState.changes,
		};
	};

	const buttonArray = getButtonArray(getBody, resetListChanges);

	const handleDisable = () => {
		if (user?.role === 'social-worker' && !user?.privileged) {
			return true;
		} else {
			return false;
		}
	};

	const disable = handleDisable();

	return (
		<PageLayout title={pageTitle} buttonArray={buttonArray} align='left'>
			<HouseComp.HouseBasicInfo inputs={[name]} disable={disable} />
			<SectionHeader>ADDRESS</SectionHeader>
			{!disable && <AddressForm key={houseId} required={false} />}
			{disable && <StaticAddressForm key={houseId} />}
			<HouseComp.HouseChildrenList
				disable={disable}
				selectorListState={childrenListState}
			/>
			<HouseComp.HouseRPList
				disable={disable}
				selectorListState={resourceParentListState}
			/>
		</PageLayout>
	);
}
