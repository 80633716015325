import { Switch, Route } from 'react-router-dom';
import { LoggedOutRoute, LoggedInRouter, TokenRoute } from './';
import {
	Invite,
	Login,
	ErrorPage,
	InvalidToken,
	DashboardWrapper,
	EmailVerify,
	FamilyConnectRedirect,
} from 'components';
import ReadOnlyContainer from '../components/UtilityComponents/PageLayout/components/ReadOnlyContainer';

export function AppRouter({ loading }) {
	return (
		<Switch>
			<TokenRoute path='/connect/:feedType?/:childId?'>
				<ReadOnlyContainer baseRoute='connect' />
			</TokenRoute>
			<LoggedOutRoute path='/familyconnections/'>
				<FamilyConnectRedirect />
			</LoggedOutRoute>
			<TokenRoute path='/auditoraccess/:feedType?/:childId?'>
				<ReadOnlyContainer baseRoute='auditoraccess' />
			</TokenRoute>
			<TokenRoute path='/readonly/:feedType?/:childId?'>
				<ReadOnlyContainer baseRoute='readonly' />
			</TokenRoute>
			<Route path='/invalidtoken/' component={InvalidToken} />
			<LoggedOutRoute path='/invite/:inviteType?' component={Invite} />
			<LoggedOutRoute path='/ownership/' component={Login} />
			<LoggedOutRoute path='/login/' component={Login} />
			<LoggedOutRoute exact path='/reset/' component={Login} />
			<Route key='verify' path='/verify' component={EmailVerify} />
			<Route path='/error/' component={ErrorPage} />
			<Route path='/'>
				<DashboardWrapper loading={loading}>
					<LoggedInRouter />
				</DashboardWrapper>
			</Route>
		</Switch>
	);
}

export default AppRouter;
