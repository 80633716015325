import { apiV2 } from '../axios';
import { getOwnUser, logoutUser } from './';
import {
	decodeToken,
	includeRole,
	mixpanelTrack,
	mixpanelSetUser,
} from '../helpers';
import {
	setAgency,
	getCasa,
	getCasaUsers,
	getBranchList,
	setChildrenList,
	getCategories,
	setFetchedHouseList,
	getCasaConnections,
} from './';
import { store, setChildSort, setChild, setUser } from '../redux';

const initializeAgencyUser = async (userRes, loadingFalse) => {
	store.dispatch(setChildSort('firstname'));

	if (includeRole('manager')) {
		Promise.all([setAgency(), getBranchList(), getCasaConnections()]);
	}

	if (includeRole('admin')) {
		Promise.all([setAgency(), getBranchList(), getCasaConnections()]);
	}

	const promiseArray = [setChildrenList(), setFetchedHouseList()];

	await Promise.all(promiseArray).finally(() => {
		loadingFalse();
	});
};

const initializeHouseUser = async (_userRes, loadingFalse) => {
	store.dispatch(setChildSort('lastname'));
	setChildrenList('lastname').then(() => {
		loadingFalse();
	});
};

const initializeChildUser = (userRes, loadingFalse) => {
	store.dispatch(setChild(userRes));
	loadingFalse();
};

const initializeCswUser = (_userRes, loadingFalse) => {
	store.dispatch(setChildSort('lastname'));
	setChildrenList('lastname').then(() => {
		loadingFalse();
	});
};

const initializeCasaUser = async (_userRes, loadingFalse) => {
	store.dispatch(setChildSort('firstname'));

	if (_userRes.role === 'admin') {
		const promiseArray = [
			getCasa(),
			getCasaUsers(),
			setChildrenList(),
			getCasaConnections(),
		];
		await Promise.all(promiseArray).finally(() => {
			loadingFalse();
		});
	} else {
		await setChildrenList();
		loadingFalse();
	}
};

const initializeUser = async (type, loadingFalse) => {
	const userSwitch = (res) => {
		switch (true) {
			case res.type === 'casauser':
				initializeCasaUser(res, loadingFalse);
				break;
			case res.type === 'child':
				initializeChildUser(res, loadingFalse);
				break;
			case res.type === 'contributor':
				initializeHouseUser(res, loadingFalse);
				break;
			case res.type === 'csw':
				initializeCswUser(res, loadingFalse);
				break;
			case res.type === 'agencyuser':
			default:
				initializeAgencyUser(res, loadingFalse);
				break;
		}
	};

	getOwnUser(type) // gets owner and sets in redux/auth headers
		.then(async (userRes) => {
			if (userRes && userRes.status !== 'error') {
				getCategories();
				userSwitch(userRes);
			} else {
				logoutUser();
				loadingFalse();
			}
		})
		.catch(() => logoutUser());
};

const createUser = async (user) => {
	return await apiV2.post('/user', user);
};

const getUser = async (userId, params) => {
	const query = new URLSearchParams(params);
	const queryString = `?${query?.toString()}` || '';
	return await apiV2.get(`/user/${userId}${queryString}`);
};

const getUserWithVerify = async (userId, pass) => {
	// uses auth-verify header //
	const readOnlyToken = true;
	const response = await apiV2.get(`/user/${userId}`, { pass, readOnlyToken });
	store.dispatch(setUser(response.data));
	return response;
};

const updateUser = async (body, userId, config) => {
	let tokenUser;
	if (!userId) {
		tokenUser = await getUserFromToken();
	}
	return await apiV2.put(`/user/${userId || tokenUser?.userId}`, body, config);
};

const getUserFromToken = async (token) => {
	if (!token) {
		token = localStorage.getItem('token');
	}
	const decodedToken = token ? decodeToken(token) : {};
	const { ownerId, userId, id } = decodedToken;
	if ((ownerId || id) && !userId) {
		decodedToken.userId = ownerId || id;
	}
	return decodedToken;
};

const activateUser = async (body, userId) => {
	return await apiV2.post(`/user/${userId}/activate`, body).then((res) => {
		res?.data && mixpanelSetUser(res.data);
		res?.data &&
			mixpanelTrack('invite.accept', { ...res.data, accepted: Date.now() });
		return res;
	});
};

const deleteUser = async (userId, pass) => {
	// uses auth-verify header //
	return await apiV2.delete(`/user/${userId}`, { pass });
};

const requestEmailChange = async (id, email) => {
	return await apiV2.post(`/user/${id}/email`, { email });
};

const verifyEmail = async (userId, token) => {
	return await apiV2.post(`/user/${userId}/email/verify`, { token });
};

const reinviteUser = async (id) => {
	return await apiV2.post(`/user/${id}/reinvite`);
};

const enableTwoFactor = async (phone, code) => {
	return await apiV2.post('/2fa/enable', {
		phoneNumber: phone,
		'2FACode': code,
	});
};

const getTwoFactorCode = async (phone) => {
	return await apiV2.post('/2fa/enable', {
		phoneNumber: phone,
	});
};

const disableTwoFactor = async (pass) => {
	// Uses Verify Auth Header //
	return await apiV2.post('/2fa/disable', { pass });
};

export {
	getTwoFactorCode,
	disableTwoFactor,
	enableTwoFactor,
	initializeUser,
	createUser,
	getUser,
	updateUser,
	getUserFromToken,
	activateUser,
	deleteUser,
	getUserWithVerify,
	requestEmailChange,
	verifyEmail,
	reinviteUser,
};
