import { useState, useEffect, useRef } from 'react';
import AuthCodeInput from 'react-auth-code-input';
import { useHistory } from 'react-router';
import { validatePhoneNumber } from 'helpers/validation/utils/validate';
import { useCreateDispatches } from 'helpers/hooks';
import { PrimaryButton, TextFieldRow } from 'components';
import LogoutButton from 'components/Account/components/LogoutButton';
import { enableTwoFactor, getTwoFactorCode } from 'services/user-services';
import { getOwnUser } from 'services';
import styles from './multiFactor.module.css';

const Enforce2fa = () => {
	const authCodeRef = useRef(null);
	const [phone, setPhone] = useState('');
	const [passcode, setPasscode] = useState('');
	const [codeSent, setCodeSent] = useState(false);

	const { setSnackbar, setLoader } = useCreateDispatches();
	const history = useHistory();

	useEffect(() => {
		setCodeSent(false);
		setPhone('');
		setPasscode('');
	}, []);

	const handleError = async (error) => {
		if (error?.response?.status === 403) {
			setSnackbar({
				variant: 'error',
				message: error.response?.data?.message,
			});
		} else {
			setSnackbar({
				variant: 'error',
				message: 'There was an error processing your request',
			});
		}
		await setLoader(false);
	};

	const handleRequestCode = async () => {
		const validPhone = validatePhoneNumber(phone);
		if (validPhone) {
			await setLoader(true);
			getTwoFactorCode(phone)
				.then(async () => {
					await setLoader(false);
					setCodeSent(true);
				})
				.catch(async (error) => {
					handleError(error);
				});
		} else {
			setLoader(false);
			setSnackbar({
				variant: 'error',
				message: 'Phone Number is not valid',
			});
		}
	};

	const handleValidateCode = async () => {
		if (passcode.length === 6) {
			await setLoader(true);
			enableTwoFactor(phone, passcode)
				.then(async (e) => {
					await getOwnUser('fetchUpdate');
					await setLoader(false);
					setSnackbar({
						variant: 'success',
						message: 'Two-Factor Authorization is now enabled',
					});
					setCodeSent(false);
				})
				.catch(async (error) => {
					handleError(error);
				})
				.finally((e) => {
					authCodeRef.current.clear();
					history.push('/lifebooks/');
				});
		} else {
			await setLoader(false);
			setSnackbar({
				variant: 'error',
				message: 'Code is not valid',
			});
		}
	};

	const handleSubmit = () => {
		codeSent ? handleValidateCode() : handleRequestCode();
	};

	return (
		<div className={styles.fullPageContainer}>
			<div className={styles.maxContainer}>
				<div className={styles.textContainerFull}>
					Two-Factor Authentication{' '}
				</div>
				<div className={styles.lowerTextContainerFull}>
					{!codeSent ? (
						<p>
							Your agency has enabled two-factor authentication for all of its
							users. Enter your phone number to receive a code to enable
							two-factor authentication.
						</p>
					) : (
						<p>
							A message with a verification code has been sent to your devices.
							Enter the code to continue.
						</p>
					)}
				</div>
				<div className={styles.inputContainerFull}>
					{!codeSent ? (
						<div className={styles.inputFull}>
							<TextFieldRow
								label={'Phone Number'}
								className={styles.phoneContainerFull}
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
							/>
						</div>
					) : (
						<AuthCodeInput
							ref={authCodeRef}
							containerClassName={styles.authContainer}
							inputClassName={styles.authInput}
							onChange={(e) => setPasscode(e)}
						/>
					)}
					<div className={styles.buttonContainerFull}>
						<PrimaryButton onClick={() => handleSubmit()}>Submit</PrimaryButton>
						<LogoutButton key='account-logout' />,
					</div>
				</div>
			</div>
		</div>
	);
};

export default Enforce2fa;
