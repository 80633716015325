import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormSectionHeader from 'components/UtilityComponents/Form/FormSectionHeader';
import styles from './multiFactor.module.css';
import { useCreateDispatches } from 'helpers/hooks';
import { usePasswordConfirm } from 'helpers';
import { disableTwoFactor, getOwnUser } from 'services';

const MultiFactor = () => {
	const user = useSelector((state) => state.user.user);

	const [enabled, setEnabled] = useState(user['2FAEnabled']);

	const { set2faModal, setSnackbar, setLoader } = useCreateDispatches();

	const dispatch = useDispatch();
	const onPasswordConfirm = usePasswordConfirm();

	useEffect(() => {
		setEnabled(user['2FAEnabled']);
	}, [user]);

	const handleDisable = async (pass) => {
		await setLoader(true);
		disableTwoFactor(pass)
			.then(async () => {
				await getOwnUser('fetchUpdate');
				await setLoader(false);
				setSnackbar({
					variant: 'success',
					message: 'Two-Factor authorization has been disabled',
				});
			})
			.catch(async (e) => {
				await setLoader(false);
				setSnackbar({
					variant: 'success',
					message: 'There was an error with your request',
				});
			});
	};

	const handleButtonClick = async () => {
		if (enabled) {
			onPasswordConfirm((e) => handleDisable(e));
		} else {
			await setLoader(false);
			dispatch(set2faModal(true));
		}
	};

	const buttonLabel = () => {
		if (enabled) {
			return 'Disable 2FA';
		} else {
			return 'Enable 2FA';
		}
	};

	return (
		<div className={styles.formSectionContainer}>
			<FormSectionHeader
				hideBottom={true}
				buttonLabel={buttonLabel()}
				onClick={() => handleButtonClick()}>
				<div className={styles.mfaContainer}>Two-Factor Authentication</div>
			</FormSectionHeader>
		</div>
	);
};

export default MultiFactor;
