/* global google */

import { useState } from 'react';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import useManageChildPermissions from '../utils/useManageChildPermissions';
import useManageChildSchoolMap from '../utils/useManageChildSchoolMap';
import { InputAdornment } from '@material-ui/core';
import * as Comp from 'components';
import { Menu, MenuItem } from '@material-ui/core';
import styles from '../child.module.css';

export default function ChildSchool({
	schoolState,
	schoolName,
	clearSchoolData,
	handleSchoolSelect,
	infoArray,
}) {
	const permissions = useManageChildPermissions();

	const { anchorRef, anchorEl, clearAnchor, enterSearch } =
		useManageChildSchoolMap();

	const permission = permissions.school;

	const [expanded, setExpanded] = useState(false);
	const [schoolList, setSchoolList] = useState([]);

	const handleOpenClose = () => {
		setExpanded(!expanded);
	};

	async function findPlaces() {
		let query =
			(schoolName.value && schoolName.value.split(' ').join('+')) || '';

		const { Place } = await google.maps.importLibrary('places');
		const request = {
			textQuery: query,
			fields: ['displayName', 'addressComponents', 'formattedAddress'],
			includedType: 'school',
			maxResultCount: 8,
			region: 'us',
			useStrictTypeFiltering: false,
		};
		//@ts-ignore
		const { places } = await Place.searchByText(request);
		if (places.length) {
			enterSearch();
			setSchoolList(places);
		} else {
			enterSearch();
			setSchoolList([{ Eg: { displayName: 'No Schools Found' } }]);
		}
	}

	const menuItemMapper = (val) => {
		const onClick = () => {
			handleSchoolSelect(val.id);
			clearAnchor();
		};

		return (
			<MenuItem
				className={styles.schoolListMenuItem}
				key={val.id}
				value={val.id}
				onClick={onClick}>
				<div className={styles.schoolName}>{val.Eg.displayName}</div>
				<div>{val.Eg.formattedAddress}</div>
			</MenuItem>
		);
	};

	let schoolListMap = schoolList.length
		? schoolList.map(menuItemMapper)
		: 'loading';

	return permission === 'edit' ? (
		<div className={styles.childSchoolContainer}>
			<Comp.SectionHeader>
				<div className={styles.basicHeader}>
					<div className={styles.headerText}>SCHOOL</div>
					{expanded ? (
						<ExpandLess onClick={() => handleOpenClose()} />
					) : (
						<ExpandMore onClick={() => handleOpenClose()} />
					)}
				</div>
			</Comp.SectionHeader>

			{expanded && (
				<div className={styles.childSchoolContent} ref={anchorRef}>
					<Comp.TextField
						id='school-input'
						className={styles.childSchoolTextField}
						variant='outlined'
						{...schoolName}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<>
										<Comp.PrimaryButton
											id='school-search-button'
											onClick={findPlaces}>
											Search
										</Comp.PrimaryButton>
										{schoolName.value ? (
											<Comp.SecondaryButton onClick={clearSchoolData}>
												Clear School
											</Comp.SecondaryButton>
										) : null}
									</>
								</InputAdornment>
							),
						}}
					/>
					{anchorEl ? (
						<>
							<div className={styles.schoolListMap} id='map' />
							<Menu
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={clearAnchor}
								getContentAnchorEl={null}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								PaperProps={{
									style: {
										width: 'calc((100vw - 590px) * 0.9)',
										maxWidth: '600px',
										minWidth: '480px',
									},
								}}>
								{schoolListMap}
							</Menu>
						</>
					) : null}

					<div className={styles.childSchoolForm}>
						{schoolState.id
							? infoArray.map((val, i) => {
									return (
										<Comp.StaticTextRow key={`school-row-${i}`} {...val} />
									);
							  })
							: null}
					</div>
				</div>
			)}
		</div>
	) : null;
}
