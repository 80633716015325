import { useHistory } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import * as services from 'services';
import { useFormInput } from 'helpers';
import { handleLoginValidation } from 'helpers/validation/loginValidation';
import { useCreateDispatches, useQuery } from 'helpers/hooks';

export default function useLoginCallbacks() {
	const username = useFormInput('Email', '');
	const password = useFormInput('Password', '');
	const { setSnackbar, setLoader, setTwoFactorModal } = useCreateDispatches();
	const history = useHistory();
	const { pathname, token } = useQuery();

	const handleValidate = () => {
		const loginValidation = handleLoginValidation(
			username.value,
			password.value
		);

		if (!loginValidation.isValid) {
			setSnackbar(
				{ variant: 'error', message: loginValidation.message },
				{ loggedIn: false }
			);
			return false;
		} else {
			return true;
		}
	};

	const getRequestBody = () => {
		return {
			email: username.value,
			password: password.value,
		};
	};

	const loadingFalse = async () => {
		const path = pathname || 'lifebooks';
		const tokenQuery = token ? `?token=${token}` : '';
		history.push(`/${path}/${tokenQuery}`);
		setLoader(false);
	};

	const login = async () => {
		const expirationLimit = 900000;
		const setActiveTime = new Date().getTime() + expirationLimit;
		const valid = handleValidate();

		// from will redirect back to protected route if login screen
		// was routed to from protected route
		// validate that fields are filled out
		if (valid) {
			setLoader(true);
			const uuid = uuidv4();
			const reqBody = getRequestBody(uuid);
			let loginResponse = await services.loginEmailUser(reqBody);
			if (!loginResponse.error) {
				if (loginResponse?.status === 202) {
					setTwoFactorModal(true);
					setLoader(false);
				} else {
					localStorage.setItem('active', setActiveTime);
					await services.setHeader(loginResponse);
					await services.initializeUser('login', loadingFalse);
				}
			} else {
				setSnackbar({
					variant: 'error',
					message:
						loginResponse.message?.response?.data?.message ||
						'There was a problem logging in, please try again.',
					twoFactor: true,
				});
				setLoader(false);
			}
		}
	};
	return { username, password, login };
}
