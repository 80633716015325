import { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setSnackbar, setMobileList } from 'redux/index';
import { useHistory } from 'react-router-dom';
import * as services from 'services';
import { MenuItem } from '@material-ui/core';
import InviteNoBranch from './components/InviteNoBranch';
import * as Comp from 'components';
import style from './sendInvite.module.css';
import InviteFormUpdated from './components/InviteFormUpdated';
import { handleInvitedUserValidation } from 'helpers/validation/invitedUserValidation';

function SendInvites(props) {
	const {
		onClose,
		type,
		managerList,
		branchList: branchListProp,
		setMobileList,
	} = props;
	const userToInvite = useSelector((state) => state.user.inviteUser);
	const [branchList, setBranchList] = useState([]);

	const dispatch = useDispatch();
	let history = useHistory();

	const getApplicableLists = useCallback(async () => {
		if (type === 'manager') {
			if (!branchListProp) {
				let branchResponse = await services.getBranchList();
				if (!branchResponse.error) {
					setBranchList(branchResponse.data);
				}
			} else {
				setBranchList(branchListProp);
			}
		}
	}, [branchListProp, type]);

	useEffect(() => {
		getApplicableLists();
	}, [getApplicableLists]);

	const managerMap =
		managerList &&
		Boolean(managerList.length) &&
		managerList.map((val) => {
			return (
				<MenuItem key={val.id} value={val.id}>
					{val.firstname ? `${val.firstname} ${val.lastname}` : val.email}
				</MenuItem>
			);
		});

	const branchMap =
		branchList &&
		Boolean(branchList.length) &&
		branchList.map((val) => {
			return (
				<MenuItem key={val.id} value={val.id}>
					{val.name}
				</MenuItem>
			);
		});

	const selectClose = () => {
		setMobileList(true);
		onClose();
	};

	const newOnInvite = async () => {
		const data = {};
		data.firstname = userToInvite.firstName;
		data.lastname = userToInvite.lastName;
		data.role = userToInvite.role;
		data.email = userToInvite.email;

		if (['socialWorker', 'mentor'].includes(type)) {
			data.managerAgencyUserId = userToInvite.manager;
		}
		if (['manager'].includes(type)){
			data.branchId = userToInvite.branchId;
		}

		const handleValidation = handleInvitedUserValidation(data, type);

		if (handleValidation.isValid) {
			await services
				.inviteAgencyUser(data).then(() => {
					history.goBack();
					dispatch(
						setSnackbar({
							variant: 'success',
							message: 'Successfully invited a user',
						})
					);
				}).catch((e) => {
					dispatch(setSnackbar({ variant: 'error', message: e.response.data.message }))
				})
		} else {
			dispatch(
				setSnackbar({
					variant: 'error',
					message: handleValidation.message,
				})
			);
		}
	};

	const buttonContainer = [
		<Comp.SecondaryButton key={'send-sw-invite'} onClick={selectClose}>
			Cancel
		</Comp.SecondaryButton>,
		<Comp.PrimaryButton
			key={'invite-sw'}
			onClick={ () => newOnInvite() }>
			Invite
		</Comp.PrimaryButton>,
	];

		return (
			<>
				<div className={style.container}>
					<Comp.PageLayout
						title='Invite'
						buttonArray={buttonContainer}
						navBack={selectClose}>
						<div className={style.content}>
							{!branchMap && type === 'manager' ? (
								<InviteNoBranch />
							) : (
								<InviteFormUpdated
									managerMap={managerMap}
									managerList={managerList}
									branchMap={branchMap}
									type={type}
								/>
							)}
						</div>
					</Comp.PageLayout>
				</div>
			</>
		);
}

export default connect(null, { setSnackbar, setMobileList })(SendInvites);
