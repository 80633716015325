import { useParams } from 'react-router';
import ActivityItem from '../components/ActivityItem';

export const capitalizeFirst = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export function getPostDescription(activity) {
	const actionName = activity.name ? `${activity.name} ` : '';

	if (
		activity.activity !== 'created' &&
		activity.variantChangedTo === 'basic'
	) {
		return capitalizeFirst(`${actionName} moved a vault post to the timeline`);
	}

	if (activity.variantChangedTo === 'vault') {
		if (!activity.moveToVaultReason) {
			return capitalizeFirst(`${actionName} moved a post to the vault`);
		} else {
			let moveToVaultReason = activity.moveToVaultReason;

			if (activity.moveToVaultReason === 'sensitiveInformation') {
				moveToVaultReason = 'it has sensitive information';
			}
			if (activity.moveToVaultReason === 'ageSpecific') {
				moveToVaultReason = 'of an age requirement';
			}
			if (activity.moveToVaultReason === 'courtRequest') {
				moveToVaultReason = 'a court request';
			}
			if (activity.moveToVaultReason === 'notAppropriate') {
				moveToVaultReason = 'it is not appropriate';
			}
			if (activity.moveToVaultReason === 'childRequest') {
				moveToVaultReason = 'of a child request';
			}
			if (activity.moveToVaultReason === 'other') {
				moveToVaultReason = 'of an undefined reason';
			}
			return capitalizeFirst(
				`${actionName} moved a post to the vault because ${moveToVaultReason}`
			);
		}
	}

	const typeDescriptor = () => {
		if (activity.deleteReason) {
			let reason = '';
			if (activity.deleteReason === 'legalReasons') {
				reason = 'legal reasons';
			}
			if (activity.deleteReason === 'courtRequest') {
				reason = 'a court request';
			}
			if (activity.deleteReason === 'userError') {
				reason = 'a user error';
			}

			return ` a post because of ${reason}`;
		}
		if (activity.activity === 'edited') {
			return ' a post';
		}
		if (activity.photoChanged) {
			return ' a photo post';
		}
		return ' a text post';
	};

	const actionDescriptor =
		activity.activity === 'edited'
			? activity.archivedChanged
				? 'deleted'
				: activity.approvedChanged
				? 'approved'
				: 'edited'
			: activity.activity || '';

	return capitalizeFirst(`${actionName}${actionDescriptor}${typeDescriptor()}`);
}

export function getProfileDescription(activity) {
	// TODO casa

	if (activity.activity === 'restarted_care') {
		// add agency name
		return 'Restarted Care';
	}

	if (activity.activity === 'exited_care') {
		// add agency name
		return 'Exited Care';
	}

	if (activity.activity === 'csw_changed') {
		// add csw names
		if (activity.newCSW) {
			if (activity.newCSW.firstname) {
				return `CSW ${activity.newCSW.firstname} ${activity.newCSW.lastname} has been added`;
			} else {
				return 'New CSW has been added';
			}
		}
		if (activity.oldCSW) {
			if (activity.oldCSW.firstname) {
				return `CSW ${activity.oldCSW.firstname} ${activity.oldCSW.lastname} has been removed`;
			} else {
				return 'CSW has been removed';
			}
		}
		return 'CSW Changed';
	}

	if (activity.activity === 'house_changed') {
		let message;

		if (activity.newHouseName && activity.oldHouseName) {
			message = `Changed house to ${activity.newHouseName}`;
		} else {
			message = activity.newHouseName
				? `Added house ${activity.newHouseName}`
				: `Removed house ${activity.oldHouseName}`;
		}

		return message;
	}

	return 'Unknown Activity';
}

export function getExportsDescription(activity) {
	const { requestedUser: { firstname } = {}, receiverRelation } =
		activity || {};

	switch (true) {
		case !!firstname && !!receiverRelation:
			return `${firstname} requested a LifeBook export for a ${receiverRelation}`;
		case !!firstname:
			return `${firstname} requested a LifeBook export`;
		case !!receiverRelation:
			return `LifeBook export requested for a ${receiverRelation}`;
		default:
			return 'LifeBook export requested';
	}
}

export function getDescriptionFunction(type) {
	switch (type) {
		case 'exportActivity':
			return getExportsDescription;
		case 'profileActivity':
			return getProfileDescription;
		case 'activity':
		default:
			return getPostDescription;
	}
}

export function useActivityMapper(lastPostActivityId) {
	const { feedType } = useParams();
	const getDescription = getDescriptionFunction(feedType);

	return (val, index, arr) => {
		const description = getDescription(val);
		return (
			<ActivityItem
				key={val.id}
				activity={{
					...val,
					description,
					index,
					category: val.category ? val.category : 'uncategorized',
					only: arr.length === 1 && index === 0,
					last: !lastPostActivityId && index === arr.length - 1,
				}}
			/>
		);
	};
}
